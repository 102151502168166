import React from 'react';

import { useTranslation } from '@almond/localization';
import { PulseProvider } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { ErrorMessage } from '~modules/errors';
import { MainForm } from '~modules/forms';
import { usePromotionCodeNotificationStatus, useReferralCodeNotificationStatus } from '~modules/payment';
import { appointmentParamsAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { useSubmitVisitReason, useVisitReasons } from '../../hooks';
import { NewMemberVisitReasons } from './NewMemberVisitReasons';
import { VisitReasons } from './VisitReasons';

export const VisitReasonsPage: React.FC = () => {
  const { t } = useTranslation();
  const { visitReasons, isLoadingVisitReasons, visitReasonsError, mutateVisitReasons } = useVisitReasons();
  const submitVisitReason = useSubmitVisitReason(visitReasons);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const searchParams = useLocalSearchParams();
  const referralNotificationStatus = useReferralCodeNotificationStatus();
  const promotionNotificationStatus = usePromotionCodeNotificationStatus();

  const showNewMemberVisitReasonsUi =
    (searchParams.is_new_member !== 'false' || isNewMemberRemote) && !searchParams.category;
  // Show ungrouped to self-booked new members (<NewMemberVisitReasons>). Returning members,
  // show the grouped view (<VisitReasons>)
  const ContentComponent = showNewMemberVisitReasonsUi ? NewMemberVisitReasons : VisitReasons;

  if (visitReasonsError) {
    return <ErrorMessage error={visitReasonsError} onTryAgain={() => mutateVisitReasons()} />;
  }

  return (
    <PulseProvider duration={1500}>
      <MainForm
        id="visitReasons"
        title={t('visitReasons.title')}
        atom={appointmentParamsAtom}
        size="L"
        statusBar={
          <>
            {referralNotificationStatus && <NotificationStatusBar {...referralNotificationStatus} />}
            {promotionNotificationStatus && <NotificationStatusBar {...promotionNotificationStatus} />}
          </>
        }
      >
        <ContentComponent isLoading={isLoadingVisitReasons} visitReasons={visitReasons} onSubmit={submitVisitReason} />
      </MainForm>
    </PulseProvider>
  );
};
