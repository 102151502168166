// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./[...catchAll].tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/[...catchAll].tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/_layout.tsx"); } },
  "./address.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/address.tsx"); } },
  "./all-set.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/all-set.tsx"); } },
  "./allergies.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/allergies.tsx"); } },
  "./auth-redirect.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/auth-redirect.tsx"); } },
  "./book-appointment.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/book-appointment.tsx"); } },
  "./cash-rates.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/cash-rates.tsx"); } },
  "./complete_on_boarding.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/complete_on_boarding.tsx"); } },
  "./confirm-email.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/confirm-email.tsx"); } },
  "./confirmed.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/confirmed.tsx"); } },
  "./consent-signature.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/consent-signature.tsx"); } },
  "./create-patient.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/create-patient.tsx"); } },
  "./credit-card.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/credit-card.tsx"); } },
  "./demographic.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/demographic.tsx"); } },
  "./doctor-info.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/doctor-info.tsx"); } },
  "./drug-allergies.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/drug-allergies.tsx"); } },
  "./family-history.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/family-history.tsx"); } },
  "./get-started.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/get-started.tsx"); } },
  "./health-conditions.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/health-conditions.tsx"); } },
  "./hospitalization.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/hospitalization.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/index.tsx"); } },
  "./infection/index.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/infection/index.tsx"); } },
  "./infection/questionnaire.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/infection/questionnaire.tsx"); } },
  "./infection/reviewing.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/infection/reviewing.tsx"); } },
  "./infection/urgent-care.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/infection/urgent-care.tsx"); } },
  "./insurance-id.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/insurance-id.tsx"); } },
  "./insurance-not-accepted.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/insurance-not-accepted.tsx"); } },
  "./insurance.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/insurance.tsx"); } },
  "./lab.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/lab.tsx"); } },
  "./learn-more.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/learn-more.tsx"); } },
  "./login.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/login.tsx"); } },
  "./medications.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/medications.tsx"); } },
  "./new-member-scheduling.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/new-member-scheduling.tsx"); } },
  "./occupation.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/occupation.tsx"); } },
  "./pernicious-habits.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/pernicious-habits.tsx"); } },
  "./pharmacy.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/pharmacy.tsx"); } },
  "./phone-verification.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/phone-verification.tsx"); } },
  "./pregnancies.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/pregnancies.tsx"); } },
  "./pricing-explanation.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/pricing-explanation.tsx"); } },
  "./recommendation.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/recommendation.tsx"); } },
  "./refer/[code].tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/refer/[code].tsx"); } },
  "./referral.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/referral.tsx"); } },
  "./scheduling.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/scheduling.tsx"); } },
  "./surgeries.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/surgeries.tsx"); } },
  "./tell-us-more.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/tell-us-more.tsx"); } },
  "./tell_us_more.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/tell_us_more.tsx"); } },
  "./typeform-response.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/typeform-response.tsx"); } },
  "./visit-categories.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/visit-categories.tsx"); } },
  "./visit-reasons.tsx": { enumerable: true, get() { return require("/home/runner/work/frontend/frontend/apps/demi/src/app/visit-reasons.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;