import { useMemo } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';

import { useSchedulingMultiday } from '../../hooks';
import { logError } from '../../logger';
import { appointmentUtilities } from '../../services';
import { isAppointmentPrimary } from './isAppointmentPrimary';

import type { MultidayParams } from '../../hooks';
import type { VisitOutcomeSchema } from '../../visitOutcomes';
import type { PhysicianTimeSlots } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

export type PhysicianTimeSlotsWithRecommendation = PhysicianTimeSlots & { recommendationType: 'primary' | 'secondary' };

/**
 *
 * @param selectedDate Currently selected day
 * @param params Params for querying for availability
 * @param visitOutcome
 * @returns Variety of data for rendering selected day's availability
 */
export const useSingleDayData = (
  selectedDate: Dayjs,
  params: MultidayParams | null,
  visitOutcome: VisitOutcomeSchema | null
) => {
  const response = useSchedulingMultiday(selectedDate, params);

  const data = useMemo<PhysicianTimeSlotsWithRecommendation[] | null>(() => {
    const selectedDateString = dateAndTimeParsers.toRemoteDate(selectedDate);

    if (!response?.data) {
      return null;
    }

    if (response.data && !Array.isArray(response.data)) {
      logError(`Incorrect type of 'multidayData'. Should be an array, but received ${typeof response.data}.`);

      return null;
    }

    const timeSlots = response.data.find(d => d.day === selectedDateString)?.physiciansTimeSlots || [];

    return timeSlots.map((timeSlot: PhysicianTimeSlots) => ({
      ...timeSlot,
      recommendationType: isAppointmentPrimary(visitOutcome, timeSlot) ? 'primary' : 'secondary',
    }));
  }, [response.data, selectedDate, visitOutcome]);

  const sortedData = useMemo<PhysicianTimeSlotsWithRecommendation[]>(() => {
    return appointmentUtilities.sortPhysicians(data || []);
  }, [data]);

  return {
    error: response?.error,
    retry: () => response?.retry(),
    data: sortedData,
    isLoading: response?.isLoading,
  };
};
