import React from 'react';

import { useTranslation } from '@almond/localization';
import { MaterialIcon, PulseProvider, SkeletonLoader, Text } from '@almond/ui';
import { useLocalSearchParams, useRouter } from 'expo-router';
import { Button, Label, ListBox, ListBoxItem, Popover, Select } from 'react-aria-components';

import { VisitReasonIcon } from '~assets';

import { useVisitReasons } from '../../hooks';
import styles from './VisitReasonSwitcher.module.css';

import type { Key } from 'react';

type VisitReasonSwitcherProps = {
  hideIcon?: boolean;
  disableSwitching?: boolean;
  onSelectionChange?: (reason: Key) => void;
};

export const VisitReasonSwitcher = (props: VisitReasonSwitcherProps) => {
  const { onSelectionChange } = props;
  const [t] = useTranslation();
  const searchParams = useLocalSearchParams();
  const { setParams } = useRouter();
  const visitReason = searchParams.visit_reason;
  const { visitReasons, isLoadingVisitReasons } = useVisitReasons();

  const filteredVisitReasons = visitReasons
    ?.filter(vr => !vr.acuityBookingUrl)
    .sort((a, b) => a.title.localeCompare(b.title));

  if (!visitReasons || !visitReason) {
    return (
      <div className={styles.wrapper}>
        <PulseProvider duration={1000}>
          {!props.hideIcon && (
            <>
              <div className={styles.visitReasonIcon}>
                <SkeletonLoader.Circle diameter={40} />
              </div>
              <SkeletonLoader.RoundedRectangle roundness={4} height={20} width={100} />
            </>
          )}
        </PulseProvider>
      </div>
    );
  }

  // IIFE because Typescript wasn't figuring out that the object wouldn't ever be undefined
  const selectedVisitReason: { title: string } =
    visitReasons.find(v => v.code === visitReason) ||
    (() => {
      if (visitReason === 'assisted_lab') {
        return { title: t('visitReasons.items.assisted_lab.title') };
      }

      return { title: visitReason };
    })();

  if (props.disableSwitching || !filteredVisitReasons || filteredVisitReasons.length <= 1) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.currentVisitReason} data-testid="VisitReasonSwitcherTrigger">
          {!props.hideIcon && (
            <div className={styles.visitReasonIcon} aria-hidden>
              <VisitReasonIcon visitReason={visitReason} />
            </div>
          )}
          <Text fontStyle="bold" size="m" aria-label={`${t('visitReason')}: ${selectedVisitReason.title}`}>
            {selectedVisitReason.title}
          </Text>
        </div>
      </div>
    );
  }

  const defaultOnSelectionChange = (reason: Key) => setParams({ visit_reason: String(reason) });
  const onSelectionChangeHandler = onSelectionChange || defaultOnSelectionChange;

  return (
    <div className={styles.wrapper}>
      <Select className={styles.select} selectedKey={visitReason} onSelectionChange={onSelectionChangeHandler}>
        <Label className={styles.srOnly}>{t('visitReason')}</Label>
        <Button
          className={[styles.currentVisitReason, styles.currentVisitReasonButton].join(' ')}
          isDisabled={isLoadingVisitReasons}
          data-testid="VisitReasonSwitcherTrigger"
        >
          {!props.hideIcon && (
            <div className={styles.visitReasonIcon} aria-hidden>
              <VisitReasonIcon visitReason={visitReason} />
            </div>
          )}
          <Text fontStyle="bold" size="m">
            {selectedVisitReason.title}
          </Text>
          {!isLoadingVisitReasons && <MaterialIcon source="keyboard-arrow-down" size={20} aria-hidden />}
        </Button>
        <Popover
          className={styles.popover}
          offset={-6}
          data-testid="VisitReasonSwitcherPopover"
          // https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1900533703
          ref={ref => ref?.addEventListener('touchend', e => e.preventDefault())}
        >
          <ListBox className={styles.listBox}>
            {filteredVisitReasons.map(vr => (
              <ListBoxItem key={vr.code} className={styles.listItem} id={vr.code} data-visit-reason={vr.code}>
                <Text size="m">{vr.title}</Text>
              </ListBoxItem>
            ))}
          </ListBox>
        </Popover>
      </Select>
    </div>
  );
};
