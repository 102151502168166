/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines */

import type { ProviderTypesEnum } from '@almond/api-types';

type ValueGetterParams = {
  isNewMember?: boolean;
  infectionOutcome?: boolean;
  showAll?: boolean;
  notSeenInLastYear?: boolean;
};

type ValueGetter<V> = (params: ValueGetterParams) => V | null;

export type VisitOutcomeSchema = {
  // Who can schedule?
  adminOnly: boolean;
  // Default Rec / is_telehealth
  isTelehealth: boolean | null;
  // Default Rec / provider_types
  providerTypes: ProviderTypesEnum[] | null;
  // If this is for a lab booking. The Visit Reasons Modal text will be different if this is true
  isLab?: boolean;
  // Default Rec Copy / provider_types
  copy: string | null;
  // Duration
  duration: number;
  // Opt-out (All)
  // P2 Acuteness level
  inSentence: string | null;
};

type VisitOutcomesSchema = Record<string, ValueGetter<VisitOutcomeSchema>>;

export type VisitOutcome = VisitOutcomeSchema;

const getCuratedFlowValue = ({ isNewMember, infectionOutcome, showAll }: ValueGetterParams) => {
  let isTelehealth = !!(isNewMember || showAll);

  if (infectionOutcome) {
    isTelehealth = false;
  }

  return {
    isTelehealth,
    copy: `We recommend ${isTelehealth ? 'a video' : 'an office'} visit with an NP or a PA`,
  };
};

const pcosMedicalType = (duration: 30 | 60, providerTypes: ProviderTypesEnum[]) => ({
  adminOnly: true,
  isTelehealth: null,
  providerTypes,
  copy: 'Book your PCOS visit',
  duration,
  inSentence: 'PCOS medical visit',
});

const pcosHealthCoachType = {
  adminOnly: true,
  isTelehealth: true,
  providerTypes: ['HC'] as const satisfies string[],
  copy: 'You will see a Health Coach over video',
  duration: 30,
  inSentence: 'PCOS health coach visit',
};

const groupPregnancyCare = {
  adminOnly: false,
  isTelehealth: false,
  providerTypes: ['MW'] as const satisfies string[],
  copy: '',
  duration: 90,
  inSentence: 'Group Pregnancy Care',
};

// null means "any" - no value restrictions.
// To represent "no secondary option", the secondary option must
// match the primary option - in that case, the user won't be
// shown a secondary value.
export const visitOutcomesSchema: VisitOutcomesSchema = {
  // Annual Well Woman
  yearly_exam: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: null,
    copy: 'We recommend you come to the office for this visit',
    duration: 30,
    inSentence: 'an Annual Well Woman Exam',
  }),
  // Birth Control Counselling
  birth_control: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend a video visit with an NP or a PA',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // New Member Infection AND new Curated Flow for returning members
  general_infection: ({ isNewMember, infectionOutcome, showAll }) => {
    const { isTelehealth, copy } = getCuratedFlowValue({ isNewMember, infectionOutcome, showAll });

    return {
      adminOnly: false,
      isTelehealth,
      providerTypes: ['NP', 'PA'],
      copy,
      duration: 30,
      inSentence: null,
    };
  },
  // New Member Hormonal Health
  hormonal_health_or_fertility: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn over video',
    duration: 30,
    inSentence: null,
  }),
  // Vulvar Skin Symptoms
  vulvar_skin_symptoms: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: isNewMember ? ['MD'] : ['NP', 'PA'],
    copy: isNewMember
      ? 'We recommend you see an ObGyn in the office'
      : 'We recommend you see an NP or a PA in the office',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // Period Management
  period_management: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: isNewMember ? ['MD'] : ['NP', 'PA'],
    copy: isNewMember ? 'We recommend a video visit with an ObGyn' : 'We recommend a video visit with an NP or a PA',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // Perimenopause & Menopause
  perimenopause_or_menopause: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn over video',
    duration: 30,
    inSentence: 'a Perimenopause or Menopause visit',
  }),
  // General Visit
  other: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: isNewMember ? null : ['NP', 'PA'],
    copy: isNewMember ? 'We recommend a video visit' : 'We recommend a video visit with an NP or a PA',
    duration: isNewMember ? 30 : 15,
    inSentence: 'a General Visit',
  }),
  // Establish Gynecology Care (new member only)
  establish_care: () => ({
    adminOnly: false,
    isTelehealth: null,
    providerTypes: null,
    copy: 'We recommend you see an NP or PA in-person',
    duration: 30,
    inSentence: 'a visit to establish care',
  }),
  // Pregnancy Termination
  pregnancy_termination: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend you see an NP or a PA in the office',
    duration: 30,
    inSentence: 'a Pregnancy Termination',
  }),
  // Preconception & Fertility
  pregnancy_preconception: ({ isNewMember }) => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn over video',
    duration: isNewMember ? 30 : 15,
    inSentence: null,
  }),
  // New Pregnancy Intro - video visit
  pregnancy_intro_call: () => ({
    adminOnly: true,
    isTelehealth: true,
    providerTypes: null,
    copy: null,
    duration: 15,
    inSentence: 'a New Pregnancy Intro - video visit',
  }),
  // Early Preg. Counseling < 8 wks
  early_pregnancy_counseling: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: null,
    copy: 'We recommend a video visit',
    duration: 30,
    inSentence: null,
  }),
  // Preg. Confirmation (8-11 wks)
  pregnancy_confirmation: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn in the office',
    duration: 30,
    inSentence: 'a Pregnancy Confirmation',
  }),
  // First Maternity (10+ wks)
  first_maternity: ({ isNewMember }) => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: 'We recommend you see an ObGyn in the office',
    duration: isNewMember ? 30 : 60,
    inSentence: 'a First Maternity visit',
  }),
  // Follow-Up Maternity
  maternity_follow_up: ({ isNewMember }) => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: isNewMember ? null : ['MD'],
    copy: isNewMember ? null : 'We recommend you see an ObGyn in the office',
    duration: isNewMember ? 30 : 15,
    inSentence: 'a Maternity Follow Up',
  }),
  assisted_lab: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MA'],
    isLab: true,
    copy: 'Coming to the office for labwork is required for this visit',
    duration: 15,
    inSentence: 'labwork',
  }),
  procedure: () => ({
    adminOnly: true,
    isTelehealth: false,
    providerTypes: null,
    copy: 'An office visit is required for this visit',
    duration: 30,
    inSentence: 'a Procedure',
  }),
  pcos_care: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['NP', 'PA'],
    copy: 'We recommend you see an NP or PA over video',
    duration: 30,
    inSentence: 'PCOS Care',
  }),
  pcos_medical_visit_1: () => pcosMedicalType(60, ['NP', 'PA']),
  pcos_medical_visit_2: () => pcosMedicalType(60, ['MD']),
  pcos_medical_visit_3: () => pcosMedicalType(30, ['NP', 'PA']),
  pcos_medical_visit_4: () => pcosMedicalType(30, ['MD']),
  pcos_health_coach_visit_1: () => pcosHealthCoachType,
  pcos_health_coach_visit_2: () => pcosHealthCoachType,
  pcos_health_coach_visit_3: () => pcosHealthCoachType,
  pcos_health_coach_visit_4: () => pcosHealthCoachType,

  health_coach_first_visit: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['HC'],
    copy: 'Book your Health Coach appointment',
    duration: 90,
    inSentence: null,
  }),
  health_coach_follow_up: () => ({
    adminOnly: false,
    isTelehealth: true,
    providerTypes: ['HC'],
    copy: 'Book your Health Coach appointment',
    duration: 45,
    inSentence: null,
  }),
  cysts_or_fibroids: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: '',
    duration: 30,
    inSentence: null,
  }),
  gynecology_surgery_consultation: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: '',
    duration: 30,
    inSentence: 'a Gynecology Surgery Consultation',
  }),
  prenatal_care_1_on_1: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: '',
    duration: 30,
    inSentence: null,
  }),
  postpartum_care_1_on_1: () => ({
    adminOnly: false,
    isTelehealth: false,
    providerTypes: ['MD'],
    copy: '',
    duration: 30,
    inSentence: null,
  }),
  group_pregnancy_care_1: () => groupPregnancyCare,
  group_pregnancy_care_2: () => groupPregnancyCare,
  group_pregnancy_care_3: () => groupPregnancyCare,
  group_pregnancy_care_4: () => groupPregnancyCare,
  group_pregnancy_care_5: () => groupPregnancyCare,
  group_pregnancy_care_6: () => groupPregnancyCare,
  group_pregnancy_care_7: () => groupPregnancyCare,
  group_pregnancy_care_8: () => groupPregnancyCare,
  group_pregnancy_care_9: () => groupPregnancyCare,
  group_pregnancy_care_10: () => groupPregnancyCare,
  group_pregnancy_care_reunion: () => groupPregnancyCare,
};
